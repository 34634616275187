
import { Component } from "vue-property-decorator";
import Table from "@/components/Table.vue";
import ManagementFooter from "@/components/ManagementFooter.vue";
import Header from "@/components/Header.vue";
import { OperatorData } from "@/@types/management";
import FacilityService from "@/services/facilities";
import { deleteNullProp } from "@/utils/Util";
import CheckAdminLogin from "@/utils/CheckAdminLogin";
import Button from "@/components/Button.vue";
import FormSet from "@/components/FormSet.vue";

@Component({
  name: "OperatorEdit",
  components: {
    Table,
    Header,
    ManagementFooter,
    Button,
    FormSet,
  },
})
export default class OperatorEdit extends CheckAdminLogin {
  private routeName = this.$route.name;
  private operatorId = this.$route.params.id;
  private loading = false;
  private deleteError = false;
  private editError = false;
  private form: OperatorData = {
    id: "",
    first_name: "",
    first_name_kana: "",
    last_name: "",
    last_name_kana: "",
    email: "",
    facility_ids: [],
    role: "",
    data_type: "",
    created_at: "",
    updated_at: "",
  };
  private showModal(name: string) {
    this.$modal.show(name);
  }

  private closeModal(name: string) {
    this.$modal.hide(name);
  }

  private async deleteOperator() {
    try {
      this.loading = true;
      await FacilityService.deleteOperator(this.operatorId);
      this.$router.push({ name: "OperatorsManagement" });
      this.loading = false;
    } catch (error: any) {
      this.showModal("deleteError");
      this.loading = false;
      throw new Error(error);
    }
  }
  private async submit() {
    this.loading = true;
    try {
      if (this.routeName === "OperatorEdit") {
        if (this.form.id) {
          const id = this.form.id;
          const payload = deleteNullProp(this.form);

          await FacilityService.updateOperator(id, payload);
          this.back();
        }
      }
      this.loading = false;
    } catch (error: any) {
      this.loading = false;
      this.showModal("saveError");
      if (error.response.data.error_msg.includes("Not Found operator")) {
        this.editError = true;
        setTimeout(() => {
          this.editError = false;
        }, 5000);
      }
      throw new Error(error);
    }
  }

  // private async getHospitalInfo() {
  //   if (this.$route.params.id) {
  //     const res = await FacilityService.get(this.$route.params.id);
  //     if (res.data.data[0]) {
  //       this.form = res.data.data[0];
  //       console.log(this.form);
  //     }
  //   }
  // }

  private async getOperatorDetail() {
    const res = await FacilityService.getOperatorById(this.operatorId);
    if (!res.data.data.length) {
      this.back();
    } else {
      this.form = res.data.data[0];
    }
  }

  private async mounted() {
    await this.getOperatorDetail();
  }

  private back() {
    this.$router.push({ name: "OperatorsManagement" });
  }
}
